@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 9px;
}

body {
  scrollbar-width: thin;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body > div:not([id='root']) {
  width: 1% !important;
}

.center-hover {
  transform: translate(-50%, 0);
}

.bottomDashboard {
  height: calc(100vh - 48px);
}

/* REACT-CHECKBOX-TREE START */
.react-checkbox-tree .rct-node {
  margin-bottom: 6px !important;
}
/* REACT-CHECKBOX-TREE END */

/* REACT-GRID-LAYOUT START */
.react-grid-item.react-grid-placeholder {
  background-color: hsla(216, 100%, 95%, 0.46) !important;
  opacity: 1 !important;
  border: 3px dashed hsla(202, 100%, 74%);
  border-radius: 10px !important;
  transition-duration: 0.2s !important;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
/* REACT-GRID-LAYOUT END */

/* ROUNDED TOP LEFT START */
.rounded-tl-3xl {
  border-radius: 24px 0 0 0;
}
/* ROUNDED TOP LEFT END */

/* NEWS LIST BOX STYLES START */
::-webkit-scrollbar {
  width: 9px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: hsl(0, 0%, 77%);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: hsl(192, 21%, 56%);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(196, 16%, 27%);
}
/* NEWS LIST BOX STYLES END */

/* MODIFY SORTABLE TREE CLASSES */
.rst__rowLabel {
  padding-right: 0 !important;
  height: 100% !important;
  display: flex !important;
  align-items: center;
}

.rst__rowTitle {
  height: 100% !important;
  display: flex !important;
  align-items: center;
}

.rst__rowContents {
  padding: 0 !important;
}
/* END MODIFY SORTABLE TREE CLASSES */

/* SIDEBAR MENU COLLAPSE STYLES */
.sidenavClosed {
  transition: width 0.3s ease-in-out;
  width: 50px;
  background-color: hsl(213, 43%, 96%);
  border-radius: 10px;
  height: 94vh;
}
.sidenavOpened {
  transition: width 0.3s ease-in-out;
}
.linkTextClosed {
  display: none;
}
.linkTextOpened {
  display: block;
}
.r-180 {
  rotate: 180deg;
}

/* POINTER ON SIDEBAR MENU */
.active-text-color {
  color: hsl(0, 0%, 100%) !important;
}
.assessment-minimized-pointer svg {
  color: hsl(192, 19%, 30%);
}
.cliper::after {
  content: '';
  width: 66%;
  height: 14px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) !important;
  position: absolute;
  right: 14px;
  z-index: 0;
}
/* POINTER ON SIDEBAR MENU END */

/* INPUT STYLES */
input[type='radio'] + label span {
  transition:
    background 0.2s,
    transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  background-color: hsl(210, 10%, 23%);
  box-shadow: 0px 0px 0px 2px white inset;
}
input[type='checkbox'].analytics-checkbox:checked + label:before {
  content: '';
  position: absolute;
  background-color: var(--counter);
  padding: 2px;
  display: inline-block;
  width: 14px;
  height: 6px;
  border: solid white;
  border-width: 2px 2px 0 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  border-radius: 0px;
  margin: 4px 0px 12px 4px;
}
input[type='checkbox'].assessment-checkbox:checked + label:before {
  content: '';
  position: absolute;
  border-color: hsl(0, 0%, 100%);
  display: inline-block;
  width: 10px;
  height: 5px;
  border: solid white;
  border-width: 2px 2px 0 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  border-radius: 0px;
  margin: 4px 0px 6px 5px;
}

.section-readonly-input {
  border: none;
}

.section-readonly-input:focus-visible {
  outline: none;
}

/* INPUT STYLES END */

/* SLIDER STYLES */
thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 4px 0 0 0;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: var(--counter) !important;
}
.rc-slider-tooltip-inner {
  font-size: 11px !important;
  padding: 4px 6px 6px 6px !important;
  height: auto !important;
  background-color: var(--counter) !important;
  font-weight: 600 !important;
  font-size: 38px;
  white-space: nowrap;
}

.wizard-box {
  background: url('./assets/KubenAnalytics/wizard_background_image_left.png'),
    url('./assets/KubenAnalytics/wizard_background_image_right.png');
  background-position:
    -5% 99%,
    100% -5%;
  background-size:
    607px 550px,
    446.5px 426.5px;
  background-repeat: no-repeat;
}
/* SLIDER STYLES END */

/* KUBEN ASSESSMENTS SLIDER STYLES */
.rc-slider-tooltip-assessments {
  width: 112px !important;
  height: 80px;
}
.rc-slider-tooltip-assessments-placement-top .rc-slider-tooltip-arrow {
  border-top-color: var(--primary) !important;
}
.rc-slider-tooltip-assessments-inner {
  font-size: 26px !important;
  color: hsl(191, 20%, 21%);
  text-align: center;
  border-radius: 5px;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  height: auto !important;
  background-color: hsl(0, 0%, 100%) !important;
  font-weight: 500 !important;
}
/* KUBEN ASSESSMENTS SLIDER STYLES END */

/* TOOLTIP STYLES */
:root {
  --tooltip-text-color: hsl(213, 43%, 96%);
  --tooltip-background-color: hsl(192, 21%, 56%);
  --tooltip-margin: 10px;
  --tooltip-arrow-size: 6px;
}

.modal-text {
  color: black;
}

.tooltip-content {
  position: absolute;
  top: 0;
  width: 250px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  line-height: 1;
  z-index: 10;
  white-space: pre-wrap;
}

.tooltip-content::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

.tooltip-content.left {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-content.left::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}
/* TOOLTIP STYLES END */
@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}
.cube,
.cube * {
  position: absolute;
  width: 76px;
  height: 76px;
}
.sides {
  animation: rotate 3s ease infinite;
  animation-delay: 0.8s;
  transform-style: preserve-3d;
  transform: rotateX(-37.5deg) rotateY(45deg);
}
.cube .sides * {
  box-sizing: border-box;
  background-color: #bbc5c8;
  border: 7.5px solid #4f6c74;
}
.cube .sides .top {
  animation: top-animation 3s ease infinite;
  animation-delay: 0ms;
  transform: rotateX(90deg) translateZ(75px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes top-animation {
  0% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(75px);
  }
  20% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(37.5px);
  }
  70% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(37.5px);
  }
  90% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(75px);
  }
  100% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(75px);
  }
}
.cube .sides .bottom {
  animation: bottom-animation 3s ease infinite;
  animation-delay: 0ms;
  transform: rotateX(-90deg) translateZ(75px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes bottom-animation {
  0% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(75px);
  }
  20% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(37.5px);
  }
  70% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(37.5px);
  }
  90% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(75px);
  }
  100% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(75px);
  }
}
.cube .sides .front {
  animation: front-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(0deg) translateZ(75px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes front-animation {
  0% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(75px);
  }
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(37.5px);
  }
  70% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(37.5px);
  }
  90% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(75px);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(75px);
  }
}
.cube .sides .back {
  animation: back-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(-180deg) translateZ(75px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes back-animation {
  0% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(75px);
  }
  20% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(37.5px);
  }
  70% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(37.5px);
  }
  90% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(75px);
  }
  100% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(75px);
  }
}
.cube .sides .left {
  animation: left-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(-90deg) translateZ(75px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes left-animation {
  0% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(75px);
  }
  20% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(37.5px);
  }
  70% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(37.5px);
  }
  90% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(75px);
  }
  100% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(75px);
  }
}
.cube .sides .right {
  animation: right-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(90deg) translateZ(75px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes right-animation {
  0% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(75px);
  }
  20% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(37.5px);
  }
  70% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(37.5px);
  }
  90% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(75px);
  }
  100% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(75px);
  }
}

/* SPINNER STYLE STARTS */
div.spinner {
  position: absolute;
  width: 54px;
  height: 54px;
  display: inline-block;
  margin: auto;
  left: 0px;
  right: 0px;
  z-index: 30;
}

div.spinner div {
  width: 5%;
  height: 16%;
  background: #797979;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade 0.5s linear infinite;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}

div.spinner div.bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

div.spinner div.bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -0.96s;
}

div.spinner div.bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -0.92s;
}
div.spinner div.bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.88s;
}
div.spinner div.bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.84s;
}
div.spinner div.bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.8s;
}
div.spinner div.bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.76s;
}
div.spinner div.bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.72s;
}
div.spinner div.bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.68s;
}
div.spinner div.bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.64s;
}
div.spinner div.bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.6s;
}
div.spinner div.bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.56s;
}
/* SPINNER STYLE ENDS */

/* HOVER STYLES */

.group:hover > div > div.group-hover-text {
  font-size: 18px;
}
.scroll-on-hover {
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}
.scroll-on-hover:hover {
  overflow-y: auto;
}
/* HOVER STYLES END */

/* TRANSITION STYLES */
.width-transition-open {
  width: 280px;
  transition: width 0.4s;
}
.width-transition-closed {
  width: 24px;
  transition: width 0.4s;
}
.width-navbar-transition-open {
  width: 400px;
  min-width: 400px;
  transition: width 0.2s;
}
.width-navbar-transition-closed {
  width: 24px;
  min-width: 24px;
  transition: width 0.2s;
}
.height-navbar-transition-open {
  height: 100%;
  padding-bottom: 20px;
  transition: height 0.3s;
}
.height-navbar-transition-closed {
  height: 0px;
  width: 0px;
  overflow: hidden;
  transition: height 0.3s;
}

/* TRANSITION STYLES END */

/* 150% ZOOM STYLES */

@media only screen and (min-width: 891px) and (max-width: 1279px) {
  .width-transition-open + div .itemstable {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .width-transition-open + div .itemstable {
    font-size: 14px;
  }
}

.width-transition-open + div.assessment-boxes {
  max-width: 100%;
  padding-right: 20px;
  margin-left: 30px;
}

/* 150% ZOOM STYLES END*/

/*new styles*/
/* summary page */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* summary page */
  .question-summary .title secondary {
    padding-top: 32px;
  }

  /* history page */

  .width-transition-closed + div .history-container {
    padding-left: 45px;
    padding-right: 25px;
  }

  .width-transition-open + div .history-container {
    margin-left: 0;
    margin-right: 0;
  }

  /* report-page */
  .width-transition-open + div .report-table {
    padding-left: 40px;
    padding-right: 0px;
  }

  .width-transition-closed + div.assessment-boxes .report-page {
    padding-right: 50px;
    padding-left: 90px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1189px) {
  .width-transition-open + div.assessment-boxes .report-page {
    padding-right: 10px;
    padding-left: 20px !important;
  }
  /* report-page */
  .width-transition-open + div .report-table {
    padding-left: 40px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 1190px) and (max-width: 1279px) {
  /* report-page */
  .width-transition-open + div .report-table {
    padding-left: 64px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  /* choose-assesment */
  .width-transition-closed + div .assesment-wrapper {
    padding-left: 110px;
    padding-right: 60px;
  }
  .width-transition-open + div .assesment-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* admin menu */
@media (min-width: 1024px) {
  .adminDashboard .width-transition-open + div .staticWrapper {
    margin-left: 325px;
  }
}

@media only screen and (min-height: 1px) and (max-height: 690px) {
  /*set end date modal */
  .modal-title {
    font-size: 20px;
    padding-bottom: 0px;
    padding-top: 10px;
    min-width: auto;
    padding-right: 20px;
  }
  .modal-title h3 {
    font-size: 20px;
  }
  .modal-info {
    max-width: 100%;
    padding-bottom: 15px;
    font-size: 14px;
    padding-top: 3px;
  }
  .orsak-item {
    display: flex;
  }
  .orsak-item > p {
    padding-top: 7px;
    padding-right: 6px;
  }
  .model-margin {
    margin-top: 0px;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0px !important;
    height: auto !important;
  }
  .allModal {
    max-width: 700px;
  }

  .staticWrapper {
    margin-top: 40px;
  }
  .assesmentIcon svg {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  a.assesmentIcon {
    font-size: 20px;
  }
  .startsidaTable {
    padding-top: 20px;
  }
  .choosePersonWrapper {
    margin-top: 30px;
  }
  .assesmentTitle {
    font-size: 24px;
  }
  .choosePersonTitle {
    font-size: 44px;
  }
  .choosePersonInfo {
    font-size: 16px;
  }
  .sokInfo,
  .sokCheckbox {
    font-size: 20px;
  }
  .sokCheckboxText {
    font-size: 16px;
  }
  .dashboardHelpInfo {
    font-size: 14px;
  }
}
.rdrCalendarWrapper {
  order: 2;
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .staticWrapper {
    padding-right: 30px;
  }
}

/* HOOVER EFFECTS */

.hov-none:hover {
  color: unset !important;
}

.hov-opacity:hover {
  color: rgba(0, 0, 0, 0.6);
}

.hov-opacity-8:hover {
  --tw-text-opacity: 0.8;
}

.hov-red:hover {
  color: hsl(0 60% 54% / var(--tw-text-opacity));
}

.hov-gray:hover {
  background-color: hsl(0 0% 92% / var(--tw-bg-opacity));
}

/* Dashboard */
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .editItem {
    font-size: 10px;
  }
}
.dashboardTitle {
  font-size: 18px;
}
.dashboardSubtitle {
  font-size: 14px;
}
.dashboardMenu {
  font-size: 16px;
}

@media only screen and (min-width: 1024px) and (max-width: 1550px) {
  .dashboardTitle {
    font-size: 16px;
  }
  .dashboardSubtitle {
    font-size: 12px;
  }
  .dashboardMenu {
    font-size: 16px;
  }

  .dashboardLink {
    font-size: 13px;
    margin-top: 4px;
  }
  .newsDashInfo {
    font-size: 12px;
  }
  /*report*/
  .reportTitle {
    margin-top: 0;
  }

  .report-title {
    margin-top: 0;
  }

  .report-page {
    margin-top: 0;
  }
  /*assesment*/
  a.assesmentIcon {
    font-size: 16px;
  }
}

.width-transition-closed + div .searchPage {
  padding-left: 35px;
  padding-right: 20px;
}

/* analitics 1500% zoom*/
@media only screen and (min-height: 1px) and (max-height: 800px) {
  .analiticsBox {
    height: 60px !important;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1525px) {
  .width-navbar-transition-closed + div.questionPosition,
  .width-navbar-transition-closed + div > button {
    position: absolute;
  }
}

.assesmentHistoryTable div:last-child .lastItemAssesment {
  top: -130px;
}

/* react date range styling */

.rdrDateDisplayItem input {
  color: hsl(0, 0%, 0%) !important;
}
/* react date range styling end */

/* PRINT STYLE STARTS */
.print-overflow {
  overflow: hidden;
}

.print-scroll {
  overflow: auto;
}

@media print {
  .dont-print-break {
    page-break-inside: avoid;
  }

  .dont-print-this {
    display: none;
  }

  .print-overflow {
    overflow: unset;
  }

  .print-bg-white {
    background-color: white !important;
  }

  .print-scroll {
    overflow: unset;
  }

  .dont-print-shadow {
    box-shadow: none !important;
  }

  .print-this {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
  }
}
/* PRINT STYLE ENDS */

/* PERSON PAGE GRAPH GRID STARTS */
.person-graph-grid {
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 20px;
  grid-gap: 20px;
}
/* PERSON PAGE GRAPH GRID ENDS */
