:root {
  --primary: hsla(334, 100%, 13%, 1);
  --secondary: hsla(6, 75%, 92%, 1);
  --extraSecondary: hsla(10, 63%, 84%, 0.5);
  --tertiary: hsla(335, 15%, 84%, 1);
  --tertiary30: hsla(335, 15%, 84%, 0.3);
  --accent: hsla(358, 100%, 79%, 1);
  --accentSecondary: hsla(6, 75%, 92%, 1);
  --counter: hsla(358, 100%, 67%, 1);
  --counterSecondary: hsla(0, 0%, 100%, 1);
  --appName: hsla(0, 0%, 100%, 1);
  --appBgColor: hsla(334, 100%, 13%, 1);
  --appContentBgColor: hsla(0, 0%, 100%, 1);
  --primaryHalf: hsla(334, 100%, 13%, 0.5);
  --primary30: hsla(334, 100%, 13%, 0.3);
  --accentSecondary-30: hsla(6, 75%, 92%, 0.3);
  --counterOpacity: hsla(358, 100%, 67%, 0.3);
  --additional: hsla(0, 0%, 95%, 1);
  --black: hsla(334, 76%, 2%, 1);
  --black30: hsla(334, 76%, 2%, 0.3);
  --gray: hsla(0, 0%, 95%, 1);
  --text-light: hsla(0, 1%, 41%, 1);

  --navigation-BgColor: hsla(334, 100%, 13%, 1);
  --navigation-sidebarItemColor: hsla(6, 75%, 92%, 1);
  --navigation-sidebarItemHoverColor: hsla(6, 75%, 92%, 1);
  --navigation-sidebarItemHoverBgColor: hsla(336, 48%, 25%, 1);

  --title-primary: hsla(334, 100%, 13%, 1);
  --title-secondary: hsla(6, 75%, 92%, 1);
  --title-accent: hsla(358, 100%, 79%, 1);
  --title-white: hsla(0, 0%, 100%, 1);

  --subtitle-primary: hsla(334, 100%, 13%, 1);
  --subtitle-secondary: hsla(0, 0%, 51%, 1);
  --subtitle-additional: hsla(0, 0%, 76%, 1);
  --subtitle-extra: hsla(0, 0%, 88%, 1);

  --error-primary: hsla(0, 60%, 54%, 1);
  --success-primary: hsla(111, 100%, 30%, 1);
  --warning-primary: hsla(38, 100%, 50%, 1);
  --caution-primary: hsla(51, 100%, 50%, 1);
  --info-primary: hsla(212, 100%, 58%, 1);

  --button-primary-bgColor: hsla(334, 100%, 13%, 1);
  --button-primary-textColor: hsla(6, 75%, 92%, 1);
  --button-primary-hoverBgColor: hsla(336, 48%, 25%, 1);
  --button-primary-hoverTextColor: hsla(6, 75%, 92%, 1);

  --button-underlined-textColor: hsla(334, 100%, 13%, 1);
  --button-underlined-hoverTextColor: hsla(6, 75%, 92%, 1);

  --button-outlined-borderColor: hsla(334, 100%, 13%, 1);
  --button-outlined-textColor: hsla(334, 100%, 13%, 1);
  --button-outlined-bgColor: hsla(0, 0%, 100%, 1);
  --button-outlined-hoverBorderColor: hsla(334, 100%, 13%, 0.5);
  --button-outlined-hoverTextColor: hsla(6, 75%, 92%, 1);
  --button-outlined-hoverBgColor: hsla(0, 0%, 100%, 1);

  --button-collapse-color: hsla(6, 75%, 92%, 1);
  --button-collapse-borderColor: hsla(334, 100%, 13%, 1);

  --dropdown-primary-textColor: hsla(334, 100%, 13%, 1);
  --dropdown-primary-borderColor: hsla(334, 100%, 13%, 1);
  --dropdown-primary-bgColor: hsla(6, 75%, 92%, 1);

  --dropdown-secondary-textColor: hsla(334, 100%, 13%, 1);
  --dropdown-secondary-borderColor: hsla(334, 100%, 13%, 1);
  --dropdown-secondary-bgColor: hsla(0, 0%, 100%, 1);

  --link-element-bgColor: hsla(334, 100%, 13%, 1);
  --link-element-primary: hsla(6, 75%, 92%, 1);
  --link-element-secondary: hsla(10, 63%, 84%, 0.5);
  --link-element-accent: hsla(358, 100%, 79%, 1);
  --link-element-counter: hsla(358, 100%, 67%, 1);
}

/* GLOBAL STYLE STARTS */

body {
  background-color: var(--appContentBgColor);
}

.app-bg {
  background-color: var(--appBgColor);
}

.content {
  background-color: var(--appContentBgColor);
  border-color: var(--appContentBgColor);
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}

/* BUTTON STYLE STARTS */
.button-underlined {
  color: var(--counter);
}

.button-underlined:hover {
  color: var(--primary);
}

.button-underlined.reports {
  color: var(--primary);
}

.button-underlined.reports:hover {
  color: var(--accent);
}

.button-radio input[type='radio']:checked + label span {
  background-color: var(--primary);
}

.button-radio span {
  border: 2px solid var(--primary);
}

.button-primary {
  background-color: var(--primary);
  color: var(--title-white);
}

.button-primary:hover {
  background-color: var(--accent);
  color: var(--primary);
}

.button-secondary {
  background-color: var(--secondary);
  color: var(--title-primary);
}

.button-secondary:hover {
  background-color: var(--primary);
  color: var(--title-white);
}

.button-outlined {
  background-color: var(--button-outlined-bgColor);
  color: var(--button-outlined-textColor);
  border-color: var(--tertiary);
}

.button-outlined:hover {
  background-color: var(--tertiary);
  color: var(--primary);
  border-color: var(--tertiary);
}

.button-radio-group {
  background-color: var(--counterSecondary);
}

.button-delete {
  color: var(--counterSecondary);
  background-color: var(--error-primary);
  border-color: var(--error-primary);
}

.card-button:hover {
  background-color: var(--accentSecondary-30);
}

.button-item {
  color: var(--primary);
  background-color: white;
  border-color: var(--tertiary);
}
.button-item:hover {
  background-color: var(--secondary);
}

/* BUTTON STYLE ENDS */

.content-wrapper {
  background-color: var(--counterSecondary);
}

.dropdown,
.dropdown-dark {
  border: 0.5px solid var(--tertiary);
  color: var(--dropdown-primary-textColor);
}

.dropdown-secondary {
  background-color: var(--dropdown-secondary-bgColor);
  color: var(--dropdown-secondary-textColor);
  border-color: var(--dropdown-secondary-borderColor);
}

.breadcrumb {
  color: var(--primary);
}

.edit-button {
  background-color: var(--primary);
}

.accept-button {
  background-color: var(--black);
  color: var(--counterSecondary);
}

.cancel-button {
  color: var(--counterSecondary);
}

.delete-confirm-button {
  background-color: var(--counter);
  border-color: var(--error-primary);
  color: var(--error-primary);
}

.dropdown-item {
  background-color: var(--counterSecondary);
}

.dropdown-item:hover {
  background-color: var(--accentSecondary);
  color: var(--counterSecondary);
}

.input,
.input input {
  color: var(--title-secondary);
  border-radius: 16px;
}

.input input::placeholder {
  color: var(--title-secondary);
}

.input-number,
.input-light {
  border-color: var(--tertiary);
  color: var(--title-secondary);
}

.input-file,
.input-admin {
  background-color: transparent;
}

.input-accent input {
  border-color: var(--accentSecondary);
}

.textarea-wrapper {
  background-color: var(--counterSecondary);
}

.textarea-wrapper textarea {
  background-color: transparent;
}

.error {
  color: var(--error-primary);
}

.background-error {
  background: var(--error-primary);
}
.background-success {
  background: var(--success-primary);
}
.background-warning {
  background: var(--warning-primary);
}

.background-caution {
  background: var(--caution-primary);
}

.background-info {
  background: var(--info-primary);
}

.error.message {
  background-color: var(--counterOpacity);
  color: var(--error-primary);
  border-color: var(--error-primary);
}

.disabled {
  color: var(--primaryHalf);
}

.edit-image {
  background-color: var(--counterSecondary);
}

.modal-wrapper,
.list-modal,
.loading-modal,
.warning-modal,
.card-wrapper {
  background-color: var(--counterSecondary);
}

.modal-text {
  color: black;
  background-color: white;
  padding: 12px 6px;
}

.card {
  border-color: var(--secondary);
}

.node {
  background-color: var(--accentSecondary-30);
  border: var(--accentSecondary);
}

.ui-table-element-wrapper {
  color: var(--title-primary);
}

.ui-table-element-wrapper:nth-child(odd) {
  background-color: var(--gray);
}

.ui-table-element-wrapper:hover {
  background-color: var(--accentSecondary);
}

.border-primary {
  border-color: var(--primary);
}

.border-secondary {
  border-color: var(--secondary);
}

.border-secondary-add {
  border-color: var(--extraSecondary);
}

.border-tertiary {
  border-color: var(--tertiary);
}

.border-error {
  border-color: var(--error-primary);
}

.border-counter-secondary {
  border-color: var(--counterSecondary);
}

.border-accent {
  border-color: var(--accent);
}

.border-counter {
  border-color: var(--counter);
}

.background {
  background-color: var(--counterSecondary);
}

.background-secondary {
  background-color: var(--secondary);
}

.background-secondary-add {
  background-color: var(--extraSecondary);
}

.background-primary {
  background-color: var(--primary);
}

.background-highlight {
  background-color: var(--navigation-sidebarItemHoverBgColor);
}

.background-accent {
  background-color: var(--accent);
}

.background-accent-add {
  background-color: var(--accentSecondary);
}

.background-counter {
  background-color: var(--counter);
}

.background-body {
  background-color: var(--title-primary);
}

.background-tertiary {
  background-color: var(--tertiary);
}

.background-tertiary-30 {
  background-color: var(--tertiary30);
}

.remove-icon {
  color: var(--counterSecondary);
  border-color: var(--counterSecondary);
  background-color: var(--error-primary);
}

.pinningIcon {
  color: var(--primary);
}

.question-circle {
  color: var(--title-primary);
}

.question-circle:hover {
  color: var(--primary);
}

button:hover.text {
  color: var(--secondary);
}

.assessment-minimized-pointer svg {
  color: var(--accent);
}

.assessment-minimized-pointer:hover svg {
  color: var(--primary);
}

.subtitle-secondary {
  color: var(--subtitle-secondary);
}

/* GLOBAL STYLES END */

/* NAVIGATION STYLE STARTS */

.navigation {
  background-color: var(--navigation-BgColor);
  color: var(--navigation-sidebarItemColor);
}

.app-name {
  color: var(--appName);
}

.sidebar-element,
.sidebar-element .icon {
  color: var(--navigation-sidebarItemColor) !important;
}

.sidebar-element:hover .icon {
  color: var(--navigation-sidebarItemHoverColor) !important;
}

.sidebar-element.active,
.sidebar-element:hover {
  background-color: var(--navigation-sidebarItemHoverBgColor) !important;
  color: var(--navigation-sidebarItemHoverColor) !important;
}

.sidebar-element.active .icon {
  color: var(--navigation-sidebarItemHoverColor) !important;
}

.sidebar-element.no-access {
  opacity: 0.4;
}

.sidebar-collapser .icon-bg {
  color: var(--secondary) !important;
}

.sidebar-collapser .icon {
  color: var(--primary);
}

.collapse-icon {
  cursor: pointer;
}

.collapse-icon:hover {
  background-color: var(--accent);
}

.collapse-icon path,
.collapse-icon line {
  stroke: var(--button-collapse-color);
}

.collapse-icon rect {
  color: var(--button-collapse-borderColor);
}

.logout {
  color: var(--error-primary);
}

/* APP SWITCHER STYLE STARTS  */

.app-switcher {
  color: var(--appName);
  transition: color 150ms ease-in;
}
.app-switcher:hover,
.app-switcher:hover .icon {
  background-color: var(--secondary);
  color: var(--primary);
}

.app-switcher-old-btn {
  background-color: var(--counterSecondary);
}

.app-switcher-old .expanded {
  background-color: var(--counterSecondary);
}

.app-switcher-item {
  color: var(--title-primary);
}

.app-switcher-item:hover {
  background-color: var(--accentSecondary-30);
}

.app-switcher-item.current-app {
  background-color: var(--accentSecondary);
}

/* APP SWITCHER STYLES END */

/* NAVIGATION STYLES END */

/* TEXT STYLE STARTS */
.text-secondary {
  color: var(--secondary);
}
.text-primary {
  color: var(--primary);
}
.text-accent {
  color: var(--accent);
}

.text-counter {
  color: var(--counter);
}

.text-muted {
  color: var(--text-light);
}

.title {
  color: var(--title-primary);
}

.title.secondary {
  color: var(--primary);
}

.title.counter {
  color: var(--counterSecondary);
}

.title.accent {
  color: var(--accent);
}

.text {
  color: var(--title-primary);
}

.subtitle {
  color: var(--subtitle-primary);
}

.subtitle.secondary {
  color: var(--subtitle-secondary);
}

.subtitle.additional {
  color: var(--subtitle-additional);
}

.subtitle.extra {
  color: var(--subtitle-extra);
}

.list-modal-element:hover {
  background-color: var(--accentSecondary-30);
}

.multiple {
  background-color: var(--counterSecondary);
}

/* TEXT STYLES ENDS */

/* START PAGE STYLES STARTS */

.start-page .button {
  color: var(--start-page-button-color) !important;
  border-color: var(--start-page-button-border) !important;
  background-color: var(--start-page-button-bg);
}

.start-page .button:hover {
  background-color: var(--start-page-button-hover);
}

.link-element {
  .text {
    color: var(--primary);
  }
  .tag {
    border-color: var(--subtitle-extra);
  }
}

.news-list .card {
  background-color: var(--counterSecondary);
}

.news-list .news-box {
  background-color: var(--tertiary30);
}

.news-box .text {
  color: var(--title-primary);
}

.news-box .date {
  color: var(--subtitle-primary);
}

/* START PAGE STYLES END */

/* DASHBOARD PAGE STYLES STARTS */
.graph-icon {
  background-color: var(--accentSecondary);
  color: var(--primary);
}

.region-switcher {
  color: var(--primary);
  border-color: var(--tertiary);
  background-color: var(--counterSecondary);
}

.dashboard-overlay,
.ui-modal-wrapper {
  background-color: var(--black30);
}

.options-dropdown-children {
  color: var(--title-primary);
}

.options-dropdown-children:hover {
  color: var(--title-secondary);
  background-color: var(--extraSecondary);
}

.options-dropdown-children:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.options-dropdown-children:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.user-list-row {
  border-color: var(--tertiary);
}

.user-list-row:hover {
  background-color: var(--secondary);
}

.add-icon,
.minus-icon {
  color: var(--title-primary);
}

.add-icon:hover,
.minus-icon:hover {
  background-color: var(--subtitle-extra);
}

.region-item:hover {
  background-color: var(--secondary);
}

.preview-text {
  color: var(--primary);
}

.icon {
  color: var(--primary);
}

.recharts-tooltip-wrapper {
  color: var(--counterSecondary);
}

.rct-title {
  color: var(--title-primary);
}

.dashboardIcon rect {
  color: var(--accent);
}

.dashboardIcon path {
  color: var(--counterSecondary);
}

.graph-wrapper,
.user-list-box {
  border: 0.6px solid var(--tertiary);
}

/* DASHBOARD PAGE STYLES ENDS */

/* ASSESSMENT PAGE STYLE STARTS  */

.link-card,
.list-card {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.checkbox {
  color: var(--primary);
}

.button-checkbox .checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.button-checkbox span {
  border: 2px solid var(--primary);
}

.button-info-tooltip {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.content-card {
  background-color: var(--accentSecondary-30);
}

.search {
  border: 1px solid var(--tertiary);
  border-radius: 16px;
}

.assessment-list-element:nth-child(odd) {
  background-color: var(--secondary);
}

.history-options-modal {
  background-color: var(--counterSecondary);
  border-color: var(--secondary);
}

.history-options-modal .item {
  color: var(--title-primary);
}

.history-options-modal .item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.history-options-modal .item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.history-options-modal .item:hover {
  background-color: var(--secondary);
}

.return-search-element {
  background-color: var(--subtitle-extra);
}

.step-wrapper {
  background-color: var(--accentSecondary-30);
  color: var(--primary);
}

/* ASSESSMNET CREATE STYLES */

.name-holder .icon .icon {
  color: var(--primary);
  margin-right: 8px;
  height: 20px;
  width: 20px;
}

.name-holder .closed:hover {
  background-color: var(--secondary);
}

.choice-card {
  background: var(--title-white);
  border-color: (--tertiary);
  color: var(--primary);
}

.choice-card:hover {
  background: var(--secondary);
}

.choice-card.disabled {
  background-color: var(--secondary);
}

.single-answer-item {
  color: var(--title-primary);
}

.single-answer-item .icon {
  color: var(--primary);
}

.single-answer-item .allowed:hover {
  border: 1px solid var(--primary);
}

.single-answer-item .chosen {
  background-color: var(--primary);
  color: var(--secondary);
}

.question {
  background-color: var(--secondary);
  color: var(--primary);
}

.current-question {
  border-color: var(--primary);
  background-color: var(--secondary);
  color: var(--primary);
}

.progress-bar {
  background-color: var(--secondary);
}

.question-tab-item {
  color: var(--primary);
}

.question-tab-item .open {
  background-color: var(--primary);
  color: var(--secondary);
}

.question-tab {
  background-color: transparent;
  border-color: var(--primary);
}

.question-nav-item,
.question-head {
  border-color: var(--primary);
}

.summary-estimated-card {
  background-color: transparent;
  color: var(--primary);
  border-top: 1px solid var(--tertiary);
}

.not-answered {
  border-color: var(--error-primary) !important;
}

.not-answered-text {
  color: var(--counterSecondary);
  background-color: var(--error-primary);
}

/* CALENDAR STYLES */

.rdrSelected,
.rdrStartEdge,
.rdrEndEdge,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrInRange,
.rdrDateDisplay {
  color: var(--primary) !important;
}

.rdrDay {
  color: var(--primary) !important;
}

.rdrDayNumber span:after {
  background-color: var(--accent) !important;
}

/* CALENDAR STYLES END */

/* ASSESSMNET CREATE STYLES END */

.choose-template-table-element {
  color: var(--title-primary);
  border-color: var(--tertiary);
}

.choose-template-table-element:hover {
  background-color: var(--tertiary);
  color: var(--counterSecondary);
}

.table-element-wrapper:last-child .choose-template-table-element {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.choose-template-table-element .icon {
  color: transparent;
}

.choose-template-table-element:hover .icon {
  color: var(--counterSecondary);
}

.property {
  background-color: var(--primary);
  color: var(--secondary);
}

/* ASSESSMENT PAGE STYLES END */

/* SUMMARY PAGE STYLES */

.summary-box {
  border-color: var(--tertiary);
}

.rc-slider-rail,
.rc-slider-track {
  background-color: var(--secondary) !important;
}

.rc-slider-handle {
  background-color: var(--primary) !important;
}

/* SUMMARY PAGE STYLES END */

/* REPORTS PAGE STYLES */

.report-table {
  background-color: var(--counterSecondary);
  border: 1px solid var(--tertiary);
}

.report-item {
  color: var(--primary);
  border: 1px solid var(--primary30);
  border-radius: 12px;
  padding: 12px 10px;
}

.report-item .icon {
  background-color: var(--accentSecondary);
}

.report-item .modal-wrapper .icon {
  background-color: transparent;
}

/* REPORTS PAGE STYLES END */

/* ANALYTICS PAGE STYLES */

.wizard-box .icon {
  color: var(--primary);
}

.wizard-box .title {
  color: var(--title-primary);
}

.progress-bar .current {
  color: var(--primary);
}

.progress-bar .other,
.progress-bar .text {
  color: var(--accent);
}

.analytics-checkbox {
  color: var(--primary);
}

input[type='checkbox'].analytics-checkbox:checked + label {
  background-color: var(--primary);
  color: var(--counterSecondary);
}

input[type='checkbox'].analytics-checkbox + label {
  background-color: var(--secondary);
  color: var(--primary);
}

input[type='checkbox'].analytics-checkbox:checked + label:before {
  background-color: var(--primary);
}

.span {
  border-color: var(--primary);
}

.span.checked {
  border-color: var(--appName);
}

.analytics-title {
  color: var(--secondary);
}

.link-box {
  border: 1px solid var(--primary);
  color: var(--primary);
}

/* RESULT PAGE STYLES */

.button-icon-submit {
  background-color: var(--primary);
}

.tooltip-content {
  background-color: var(--secondary);
  color: var(--title-primary);
}

.tooltip-content::before {
  border-right-color: var(--secondary) !important;
}

.page-bar-chart .icon {
  color: var(--primary);
}

.button-dropdown.summary {
  background-color: var(--accent);
}

.button-dropdown {
  background-color: var(--secondary);
}

.dropdown-items-wrapper {
  background-color: var(--secondary);
  border-color: var(--primary);
}

.t-head {
  background-color: var(--primary);
  color: var(--counterSecondary);
  border-color: var(--primary);
}

.table-menu-element {
  color: var(--title-primary);
}

.table-menu-element:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.table-menu-element:hover {
  background-color: var(--accentSecondary);
}

.end-date-modal {
  background-color: var(--secondary);
}

.analytics-region {
  color: var(--navigation-sidebarItemColor);
}

/* RESULT PAGE STYLES END */

/* ANALYTICS PAGE STYLES END */

/* ADMIN PAGE STYLES */

.card-header,
.table-header {
  background-color: var(--primary);
  color: var(--secondary);
}

.foldable-card {
  background-color: var(--primary);
  color: var(--counterSecondary);
  border-color: var(--primary);
}

.advanced-table-element {
  background-color: var(--accentSecondary-30);
  border-color: var(--primary);
  color: var(--title-primary);
}

.advanced-table-item-expanded {
  background-color: var(--accentSecondary-30);
  border-color: var(--primary);
  border-top: 0px;
}

/* ADMIN PAGE STYLES END */

/* SETTINGS PAGE STYLES */

.theme-picker .image {
  border: 2px solid transparent;
  border-radius: 8px;
}

.theme-picker .image.active {
  border-color: var(--accent);
}

.theme-picker .image:hover {
  border-color: var(--accent);
  transition: all 150ms ease-in-out;
}

/* SETTINGS PAGE STYLES END */

/* PAGE IMAGE STYLES */

.pageImage .bg {
  color: var(--link-element-bgColor);
}

.pageImage .primary {
  color: var(--link-element-primary);
}

.pageImage .secondary {
  color: var(--link-element-secondary);
}

.pageImage .accent {
  color: var(--link-element-accent);
}

.pageImage .counter {
  color: var(--link-element-counter);
}

/* PAGE IMAGE STYLES END */

/* LOADER STYLES */

.cube .sides * {
  box-sizing: border-box;
  background-color: var(--secondary);
  border: 7.5px solid var(--primary);
}

.cube {
  border-radius: 16px;
}

.spinner {
  fill: var(--accent);
  color: var(--tertiary);
}

/* LOADER STYLES END */
